<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox">
    <g >
      <slot />
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'box'
      },
      width: {
        type: [Number, String],
        default: 18
      },
      height: {
        type: [Number, String],
        default: 18
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      },
      viewBox: {
        type: String,
        default: '0 0 10 10',
      }
    }
  }
</script>

<style scoped>
  svg {
    /*display: inline-block;*/
    vertical-align: baseline;
    /*margin-bottom: -2px; !* yes, I'm that particular about formatting *!*/
  }
</style>
